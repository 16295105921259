(function ($) {
  Drupal.behaviors.verticalCarouselNavigationV1 = {
    formatter: $('.vertical-carousel-navigation-v1'),
    speed: 800,
    topHeight: Drupal.cl.getTopHeight(),
    main: $(window),
    isMobile: $('body').hasClass('device-mobile'),

    initFormatter: function ($formatter, i) {
      var self = this;
      var $dots = $formatter.children('.dots');
      var $mods = $formatter.children('.module');
      var numMods = $mods.length;
      var $main = self.main;
      var th = self.topHeight;
      var params = {
        offset: { top: -th }
      };

      // Hide the TOC if:
      // - It's not enabled via the CMS
      // - There are less than 2 merch windows on the page
      // - The scrollTo library doesn't exist for whatever reason
      // - This is the mobile site
      if ($dots.hasClass('hide') || (numMods < 2) || ($.isFunction($.fn.scrollTo) == false) || (self.isMobile == true)) {
        return;
      }

      // Create all of the dots
      var $item = '';

      for (var k = 0; k < numMods; k++) {
        $item = $('<div class="item dot"><span class="title"></span></div>');
        $dots.append($item);
      }

      var $dotList = $dots.children('.dot');
      var fireScroll = true;

      // Loop through each dot item, to get the titles of each and give that as the dot "title"
      $dotList.each(function (index) {
        $(this).find('.title').text($($mods[index]).find('.short-titled').data('short-title'));
      });

      // Attach a 'click' event handler to each dot, and
      // create hover effects for the dots
      $dotList.on('click', function(e) {
        var thisIdx = $(this).index() - 1;
        var $element = $mods.eq(thisIdx);

        // For Omniture tagging
        $(window).trigger('HOME:SCROLL:CLICK', [thisIdx]);
        fireScroll = false;
        $main.scrollTo($element, self.speed, params);

        return false;
      }).hover(function () {
        $(this).toggleClass('hover');
        fireScroll = true;
      });

      // Add top/bottom arrows
      var $first = $('<div class="item first"></div>');
      var $last = $('<div class="item last"></div>');

      $first.on('click', function(e) {
        fireScroll = false;
        $(window).trigger('HOME:FIRST', [0]);
        $main.scrollTo(0, self.speed, params);

        return false;
      });
      $last.on('click', function(e) {
        var idx = $(this).index() - 1;

        fireScroll = false;
        $(window).trigger('HOME:LAST', [idx]);
        $main.scrollTo($mods.last(), self.speed, params);

        return false;
      });
      $dots.prepend($first);
      $dots.append($last);

      // Set up waypoint triggers for scrolling
      if (typeof $.fn.waypoint !== 'undefined') {
        $mods.waypoint(function (direction) {
          var $dot = $dots.children('.dot');
          var thisIndex = $(this.element).index() - 1;

          // This triggers the 1st item to be 'active' whenever the user scrolls 'up' above the 2nd item
          // Without this, the 1st item won't ever become active
          if (direction == 'up' && thisIndex == 1) {
            thisIndex = 0;
          }
          // For Omniture Tagging
          if (fireScroll) {
            $(window).trigger('HOME:SCROLL', [thisIndex]);
          }
          $dot.removeClass('hover active');
          $dot.eq(thisIndex).addClass('active');
        }, { offset: '50%' });
      }

      // Set up ipad swiping for the entire formatter
      // if ($.isFunction($.fn.touchwipe)) {
      //   $formatter.touchwipe({
      //     wipeLeft:  function() { },
      //     wipeRight: function() { },
      //     wipeUp:    function() { },
      //     wipeDown:  function() { },
      //     preventDefaultEvents: true
      //   });
      // }

      // And lastly, show the dots
      $dots.removeClass('hidden');
    },

    init: function () {
      var self = this;

      self.formatter.once('vcn-dots').each(function (i) {
        self.initFormatter($(this), i);
      });
    },

    attach: function (context, settings) {
      this.init();
      $(window).trigger('HOME:SCROLL:INIT', [$('.vertical-carousel-navigation-v1').children('.module').length]);
    }
  };
})(jQuery);
